import React, { useEffect } from 'react';
import { Link } from 'gatsby';

// Hooks, Querys & State
import { useAppState } from '../state/appState';

import { PageMeta } from '../components/pageMeta';

const NotFoundPage = () => {

  const { toggleHeaderLight } = useAppState();

  useEffect(() => {
    toggleHeaderLight(false);
  }, [toggleHeaderLight]);

  return (
    <>
      <PageMeta
        metaTitle="404: Not Found"
        metaDescription="We can't seem to find the page you are looking for."
      />
      <div className="px-gutter lg:px-12 py-40 md:py-64 xl:py-80 flex flex-col items-center text-center gap-8 max-w-5xl mx-auto">
        <h1 className="text-sm lg:text-lg font-bold text-brand">404</h1>
        <h2 className="text-3xl md:text-5xl lg:text-7xl font-bold uppercase">
          We can't seem to find the page you are looking for.
        </h2>
        <Link to="/" className="btn-rounded">
          Go back to home
        </Link>
      </div>
    </>
  );
}

export default NotFoundPage;
